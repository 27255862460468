
import { ref } from 'vue'
import { useRoute,useRouter } from 'vue-router'
import { api } from '@/api/useAxios'
import { getUserId } from '@/utils/auth'
import { update } from '@/api/entity/homework'
import { changeShareInfo } from '@/utils/wechat'
import { timeFormat } from '@/utils/util'

export default {

  setup: () => {
    const route = useRoute()
    const router = useRouter()
    const { homeworkId } = route.params
    const { needChangeReadStatus, showTag } = route.query
    const showSelf = ref(false)
    const homework = ref<any>()
    const show = ref(false)
    if (showTag) show.value = true
    const qrywork = () => {
      api.post('/homework/getHomeworkDetailNew', {
        homeworkId: homeworkId
      }).then((r) => {
        homework.value = r
        if(r.userId === Number(getUserId())){
          showSelf.value = true
        }
        changeShareInfo({
          title: homework.value.workName,
          desc: '导师点评：' + homework.value.teaEva,
          link: window.location.href,
          imgUrl: homework.value.videoCover
        })
      })
    }
    qrywork()
    if (needChangeReadStatus === 'true') {
      update({
        id: homeworkId,
        unread: 1
      })
    }
    const imge = require('@/assets/imgs/share.png')

    return {
      showSelf,
      homework,
      timeFormat,
      watchmore: () => {
        router.push('/study')
      },
      show,
      imge
    }
  }
}
